import axios from 'axios';
import methods from '@/enums/methods';
import Thoth from '../base/thoth';

const BASE_URL = process.env.VUE_APP_URL;

class BalanceService extends Thoth {
    // eslint-disable-next-line class-methods-use-this
    getExample() {
        return axios.get(`${BASE_URL}/static/balanco.xlsx`, {
            maxBodyLength: Infinity,
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/octet-stream',
            },
        });
    }

    async revoke(balanceId) {
        await this.fetch({
            method: methods.DELETE,
            url: `/balance/${balanceId}`,
        });
    }

    importFile(input) {
        const formData = new FormData();
        const {
            file,
            stateDebt,
            federalDebt,
            judicialRecovery,
            creditRequested,
            process,
            protest,
            user,
            type,
        } = input;

        formData.append('balance', file);

        formData.append('state_debt', stateDebt);
        formData.append('federal_debt', federalDebt);
        formData.append('judicial_recovery', judicialRecovery);
        formData.append('process', process);
        formData.append('protest', protest);
        formData.append('user', user);
        formData.append('type', type);

        if (creditRequested !== undefined) {
            formData.append('credit_requested', creditRequested);
        }

        return this.fetch({
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
            method: methods.POST,
            url: 'balance',
        });
    }

    viewBalances(
        balanceId,
    ) {
        return this.fetch({
            url: `/balance/file/${balanceId}`,
        });
    }

    viewAllBalancesCompanies() {
        return this.fetch({
            url: '/balance/company',
        });
    }

    viewAllBalanceFileByCompanyId(id) {
        return this.fetch({
            url: `/balance/company/${id}/file`,
        });
    }

    viewAllDateByCompany(id) {
        return this.fetch({
            url: `balance/company/${id}/dates`,
        });
    }

    addComment(
        user,
        balanceId,
        opinion,
    ) {
        return this.fetch({
            url: `balance/${balanceId}/comment`,
            data: {
                opinion,
                user,
            },
            method: methods.POST,
        });
    }

    viewComment(
        balanceId,
    ) {
        return this.fetch({
            url: `balance/${balanceId}/comment`,
        });
    }

    viewSerasa(taxId) {
        return this.fetch({
            url: '/serasa/balance',
            params: { taxId },
        });
    }
}

export default new BalanceService();
