<template>
  <div>
    <b-table
      id="risk-table"
      :fields="fieldsWithDate"
      :items="items"
      responsive="xl"
      striped
      thead-class="text-center bg-primary"
    >
      <template #cell(first)="data">
        <span :class="`${data.item.first.is_negative ? 'text-danger' : ''} text-center d-block`"> {{ data.item.first.value }}
        </span>
      </template>

      <template #cell(second)="data">
        <span :class="`${data.item.second.is_negative ? 'text-danger' : ''} text-center d-block`">
          {{ data.item.second.value }}
        </span>
      </template>

      <template #cell(third)="data">
        <span :class="`${data.item.third.is_negative ? 'text-danger' : ''} text-center d-block`"> {{ data.item.third.value }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { DateTime } from 'luxon';

const numbers = {
    1: 'first',
    2: 'second',
    3: 'third',
};

export default {
    components: {
        BTable,
    },

    props: {

        dates: {
            type: Array,
            required: true,
        },

        data: {
            type: Array,
            required: true,
        },

        properties: {
            type: Object,
            required: true,
        },
    },

    computed: {
        fieldsWithDate() {
            const datesToField = this.dates.map((item, index) => ({
                label: DateTime.fromISO(item).plus({ days: 1 }).toFormat(
                    'dd/MM/yyyy',
                ),
                key: numbers[index + 1],
            }));

            return [
                { label: '', key: 'type' },
                ...datesToField,
            ];
        },

        items() {
            const data = [];

            this.data.forEach((item, index) => {
                const objectSroted = Object.entries(item).sort((a, b) => {
                    const objectInArr = Object.entries(this.properties);

                    const indiceA = objectInArr.findIndex(item2 => item2[0] === a[0]);
                    const indiceB = objectInArr.findIndex(item2 => item2[0] === b[0]);

                    return indiceA - indiceB;
                });

                objectSroted.forEach(([key, value]) => {
                    const test = this.properties[key];

                    if (test) {
                        data.push({
                            type: test.name || '',
                            [numbers[index + 1]]: {
                                value: test.type.formater(value),
                                is_negative: test.type.negativeValidate(value),
                            },
                        });
                    }
                });
            });

            const test = data.reduce((result, currentItem) => {
                const existingItem = result.find(item => item.type === currentItem.type);

                if (existingItem) {
                    Object.assign(existingItem, currentItem);
                } else {
                    result.push(currentItem);
                }

                return result;
            }, []);
            return test;
        },
    },
};
</script>
