<template>
  <div>
    <b-row class="px-1 justify-content-between">
      <h4
        v-for="item in title.split(' ')"
        :key="item"
      >
        {{ item }}
      </h4>
    </b-row>
    <b-table
      :fields="[
        { key: 'type', label: '' },
        { key: 'value', label: '' },
      ]"
      :items="[
        { type: 'Pefin', value: pefin.value },
        { type: 'Refin', value: refin.value },
        { type: 'Protesto', value: protest.value },
        { type: 'Ação Judicial', value: lawSuit.value },
        { type: 'Divida Vencida', value: expiredDebts.value },
        { type: 'Cheque Sem Fundo', value: badCheck },
        { type: 'Liminar', value: hasInjuction },
        { type: 'Total', value: total}
      ]"
    >
      <template #cell(value)="currentData">
        <span v-if="(typeof currentData.item.value) === 'boolean'">
          {{ currentData.item.value ? 'Sim' : 'Não' }}
        </span>

        <span v-else>
          {{ currentData.item.value | value }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BRow } from 'bootstrap-vue';

export default {
    components: {
        BTable, BRow,
    },

    props: {
        pefin: {
            type: Object,
            default: () => ({
                value: 0,
            }),
        },

        refin: {
            type: Object,
            default: () => ({
                value: 0,
            }),
        },

        protest: {
            type: Object,
            default: () => ({
                value: 0,
            }),
        },

        lawSuit: {
            type: Object,
            default: () => ({
                value: 0,
            }),
        },

        expiredDebts: {
            type: Object,
            default: () => ({
                value: 0,
            }),
        },

        hasInjuction: {
            type: Boolean,
            default: () => false,
        },

        badCheck: {
            type: Boolean,
            default: () => false,
        },

        title: {
            type: String,
            default: () => 'Serasa',
        },
    },

    computed: {
        total() {
            const values = [this.pefin, this.refin, this.protest, this.lawSuit, this.expiredDebts];
            return values.reduce((acc, item) => acc + item.value, 0);
        },
    },
};
</script>
