var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"px-1 justify-content-between"},_vm._l((_vm.title.split(' ')),function(item){return _c('h4',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('b-table',{attrs:{"fields":[
      { key: 'type', label: '' },
      { key: 'value', label: '' } ],"items":[
      { type: 'Pefin', value: _vm.pefin.value },
      { type: 'Refin', value: _vm.refin.value },
      { type: 'Protesto', value: _vm.protest.value },
      { type: 'Ação Judicial', value: _vm.lawSuit.value },
      { type: 'Divida Vencida', value: _vm.expiredDebts.value },
      { type: 'Cheque Sem Fundo', value: _vm.badCheck },
      { type: 'Liminar', value: _vm.hasInjuction },
      { type: 'Total', value: _vm.total}
    ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(currentData){return [((typeof currentData.item.value) === 'boolean')?_c('span',[_vm._v(" "+_vm._s(currentData.item.value ? 'Sim' : 'Não')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(currentData.item.value))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }