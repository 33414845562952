import { types } from '../../translate-object';

export const propertiesSummary = {
    gross_revenue: {
        name: 'Receita Bruta',
        type: types.currency,
    },
    average_monthly_income: {
        name: 'Faturamento Médio Mensal',
        type: types.currency,
    },
    gross_profit: {
        name: 'Lucro Bruto',
        type: types.currency,
    },
    ebitida: {
        name: 'Ebitda',
        type: types.currency,
    },
    result: {
        name: 'Resultado do Exercício',
        type: types.currency,
    },
    loans: {
        name: 'Empréstimos e Financiamentos',
        type: types.currency,
    },
    net_worth: {
        name: 'Patrimonio Liquido',
        type: types.currency,
    },
    gross_margin: {
        name: 'Margem Bruta',
        type: types.percent,
    },
    net_margin: {
        name: 'Margem Liquida',
        type: types.percent,
    },
    general_liquidity: {
        name: 'Liquidez Geral',
        type: types.default,
    },

    current_liquidity: {
        name: 'Liquidez Corrente',
        type: types.default,
    },

    dry_liquidity: {
        name: 'Liquidez Seca',
        type: types.default,
    },
    net_debt: {
        name: 'Dívida Liquida',
        type: types.currency,
    },

    ebitida_projected: {
        name: 'Ebitda Projetado',
        type: types.currency,
    },
    net_debt_ebitida: {
        name: 'Dívida Liquida/Ebitda',
        type: types.default,
    },

    net_debt_ebitida_projected: {
        name: 'Dívida Liquida/Ebitda Projetado',
        type: types.default,
    },

    divide_service: {
        name: 'Serviço da Dívida',
        type: types.default,
    },
};
