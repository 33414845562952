<template>
  <b-row>
    <b-col md="12">

      <h4> Relatório </h4>
      <BalanceTable
        :data="report"
        :dates="dates"
        :properties="propertiesWithData"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
    BRow, BCol, BTable, BCard,
} from 'bootstrap-vue';
import BalanceTable from '../Table.vue';

import CardHeader from '../Card/CardHeader.vue';

import { gradeColor } from '../grade-color';

import { propertiesSummary as properties } from './properties';

export default {
    components: {
        BalanceTable,
        BRow,
        BCol,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },

        ignoreProperties: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            properties,
        };
    },

    computed: {
        dates() {
            return this.data?.summary.filter(item => +item.average_monthly_income).map(item => item.date) || [];
        },

        propertiesWithData() {
            const data = {};
            const existsInsolvencyFactor = this.report.some(item => +item.insolvency_factor);
            Object.entries(this.properties).forEach(([key, item], index) => {
                if (existsInsolvencyFactor) {
                    data[key] = item;
                } else if ([0, 1].some(validatePermission => validatePermission === index)) {
                    data[key] = item;
                }
            });
            return data;
        },

        report() {
            return this.data?.summary.filter(item => +item.average_monthly_income) || {};
        },

        props() {
            const { ignoreProperties } = this;
            if (!ignoreProperties.length) return properties;
            return Object.keys(properties).filter(item => !ignoreProperties.some(item));
        },
    },

    mounted() {
    },
};

</script>
