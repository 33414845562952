import {
    formatDate, formatTaxId, formatValue, formatPercent,
} from '@/utils';

const negative = value => value < 0;

export const types = {
    currency: {
        negativeValidate: negative,
        formater: formatValue,
    },

    default: {
        negativeValidate: value => negative(+value),
        formater: value => value,
    },

    percent: {
        negativeValidate: negative,
        formater: value => `${(value * 100).toFixed(2)}%`,
    },

    alreadyInPercent: {
        negativeValidate: negative,
        formater: value => `${(value)}%`,
    },
};
