export const gradeColor = {
    1: {
        text: 'white',
        background: 'danger',
        message: 'Díficil',
    },

    2: {
        text: 'white',
        background: 'warning',
        message: 'Satisfatório',
    },

    3: {
        text: 'white',
        background: 'info',
        message: 'Excelente',
    },

    4: {
        text: 'white',
        background: 'success',
        message: 'Bom',
    },

    5: {
        text: 'black',
        background: 'warning',
        message: 'Cuidado / Atenção',
    },

    6: {
        text: 'black',
        background: 'danger',
        message: 'Atenção',
    },
};
